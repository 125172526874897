export const GROUP_CREATE_REQUEST = "GROUP_CREATE_REQUEST";
export const GROUP_CREATE_SUCCESS = "GROUP_CREATE_SUCCESS";
export const GROUP_CREATE_FAIL = "GROUP_CREATE_FAIL";

export const GROUP_ADD_REQUEST = "GROUP_ADD_REQUEST";
export const GROUP_ADD_SUCCESS = "GROUP_ADD_SUCCESS";
export const GROUP_ADD_FAIL = "GROUP_ADD_FAIL";

export const GROUP_LIST_REQUEST = "GROUP_LIST_REQUEST";
export const GROUP_LIST_SUCCESS = "GROUP_LIST_SUCCESS";
export const GROUP_LIST_FAIL = "GROUP_LIST_FAIL";

export const GROUP_CODES_REQUEST = "GROUP_CODES_REQUEST";
export const GROUP_CODES_SUCCESS = "GROUP_CODES_SUCCESS";
export const GROUP_CODES_FAIL = "GROUP_CODES_FAIL";

export const GROUP_LEAVE_REQUEST = "GROUP_LEAVE_REQUEST";
export const GROUP_LEAVE_SUCCESS = "GROUP_LEAVE_SUCCESS";
export const GROUP_LEAVE_FAIL = "GROUP_LEAVE_FAIL";
